import { default as appcIKN7O1TY8Meta } from "/vercel/path0/pages/app.vue?macro=true";
import { default as banned2L4Nd51SNSMeta } from "/vercel/path0/pages/banned.vue?macro=true";
import { default as boostSRxm41l1EeMeta } from "/vercel/path0/pages/boost.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as cancelfKVWVG3qyaMeta } from "/vercel/path0/pages/payments/cancel.vue?macro=true";
import { default as successxB9ceA6v4MMeta } from "/vercel/path0/pages/payments/success.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as _share_idTfWxckCn5DMeta } from "/vercel/path0/pages/share/_share_id.vue?macro=true";
import { default as shopbVVgDM89cGMeta } from "/vercel/path0/pages/shop.vue?macro=true";
export default [
  {
    name: "app",
    path: "/app",
    component: () => import("/vercel/path0/pages/app.vue").then(m => m.default || m)
  },
  {
    name: "banned",
    path: "/banned",
    component: () => import("/vercel/path0/pages/banned.vue").then(m => m.default || m)
  },
  {
    name: "boost",
    path: "/boost",
    component: () => import("/vercel/path0/pages/boost.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-cancel",
    path: "/payments/cancel",
    component: () => import("/vercel/path0/pages/payments/cancel.vue").then(m => m.default || m)
  },
  {
    name: "payments-success",
    path: "/payments/success",
    component: () => import("/vercel/path0/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "share-_share_id",
    path: "/share/_share_id",
    component: () => import("/vercel/path0/pages/share/_share_id.vue").then(m => m.default || m)
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  }
]