import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { initializeFirestore, persistentLocalCache } from 'firebase/firestore'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const firebaseApp = initializeApp(nuxtApp.$config.public.firebaseConfig)
  nuxtApp.provide('firestore', initializeFirestore(firebaseApp, { localCache: persistentLocalCache({}) }))
  nuxtApp.provide('analytics', getAnalytics(firebaseApp))
})
