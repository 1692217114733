import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S47nQHVSFU from "/vercel/path0/node_modules/@nathanchase/nuxt-dayjs-module/dist/runtime/plugin.mjs";
import plugin_ZGw8LlWmeq from "/vercel/path0/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import firebase_client_nS52P0yitD from "/vercel/path0/plugins/firebase.client.js";
import supabase_client_ZV3B43F9vm from "/vercel/path0/plugins/supabase.client.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import mixin_rAVpZ7aCmW from "/vercel/path0/plugins/mixin.js";
import pinia_Uphuq97G1L from "/vercel/path0/plugins/pinia.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_S47nQHVSFU,
  plugin_ZGw8LlWmeq,
  firebase_client_nS52P0yitD,
  supabase_client_ZV3B43F9vm,
  sentry_client_GoGQuZo4Et,
  mixin_rAVpZ7aCmW,
  pinia_Uphuq97G1L
]