import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'

dayjs.extend(utc)

export const useMainStore = defineStore('main', {
  state: () => ({
    user: null,
    counter: null,
    enterAt: null,
    sponsors: [],
    accessToken: null,
    drinks: [],
    pageLoading: false,
    whitePageLoading: false,
    countersToAvoid: [],
    showLoginModal: false,
    showBoostModal: false,
    counterMusic: null,
    volume: {
      music: 20,
      people: 5,
      drinks: 50,
      notification: 50,
      isMuted: false,
    },
  }),
  actions: {
    setUser(user) {
      if (!user) {
        this.user = null
        return
      }

      this.user = {
        ...user,
        boosted: user.boostEndsAt ? dayjs.utc(user.boostEndsAt).isAfter(dayjs.utc()) : false,
      }
    },
    setSponsors(sponsors) {
      this.sponsors = sponsors
    },
    setCounterEnv({ counter, enterAt }) {
      this.counter = counter
      this.enterAt = enterAt
    },
    setAccessToken(token) {
      this.accessToken = token
    },
    setDrinks(drinks) {
      this.drinks = drinks
    },
    setPageLoading(loading) {
      this.pageLoading = loading
    },
    setWhitePageLoading(loading) {
      this.whitePageLoading = loading
    },
    appendCounterToAvoid(counterId) {
      this.countersToAvoid = this.countersToAvoid.concat([counterId])
    },
    setShowLoginModal(showLoginModal) {
      this.showLoginModal = showLoginModal
    },
    setShowBoostModal(showBoostModal) {
      this.showBoostModal = showBoostModal
    },
    adjustVolume(newVol) {
      this.volume = { ...this.volume, ...newVol }
    },
    setMuted(isMuted) {
      this.volume.isMuted = isMuted
    },
    setCounterMusic(music) {
      this.counterMusic = music
    },
  },
})
