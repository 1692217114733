

import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { sentryDSN, environment } } = useRuntimeConfig()
  if (!sentryDSN) {
    return
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    environment,
    dsn: sentryDSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      }),
      Sentry.captureConsoleIntegration()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, 
  })

  // Set up the global error handler
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.error('Global error:', error)

    // Capture the error with Sentry
    Sentry.captureException(error, {
      extra: {
        componentName: instance?.$options?.name,
        info,
      },
    })

    // Prevent the error from bubbling up and causing the green screen
    return false
  }

  return {
    provide: {
      sentry: Sentry,
    },
  }
})