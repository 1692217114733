

<template>
  <NuxtNotifications
    position="top center"
    :duration="5000"
  >
    <template #body="props">
      <div
        class="dom-notification"
        :class="props.item.type"
      >
        <div class="w-10">
          <img
            v-if="props.item.type === 'success'"
            src="public/notification/success.png"
          >
          <img
            v-else-if="props.item.type === 'error'"
            src="public/notification/info.png"
            class="rotate-180"
          >
          <img
            v-else
            src="public/notification/info.png"
          >
        </div>
        <div class="custom-template-content">
          <div
            v-if="props.item.title"
            class="custom-template-title"
          >
            {{ props.item.title }}
          </div>
          <div
            class="text-center"
            v-html="props.item.text"
          />
        </div>
      </div>
    </template>
  </NuxtNotifications>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.dom-notification {
  @apply text-sm m-2 mt-4 p-3 text-black border-2 border-black flex items-center gap-3 shadow-md;

  background: #E2E4E3;

  &.success {
    background: #C1FFDE;
   }
  &.error {
    background: #FFD0C1;
   }
}
</style>
