export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"propeller","content":"a2a2adaa1741443707edbb773745918b"},{"name":"description","content":"Meet new friends & enjoy unique experience in our exclusive virtual cocktails bar. Welcome to Drinks On Me."},{"name":"keywords","content":"Bar, Virtual, Cocktails, Drinks, DrinksOnMe, Ambient, Music, Conversation, Bartender"},{"property":"og:title","content":"Drinks On Me - Virtual Bar"},{"property":"og:description","content":"Meet new friends & enjoy unique experience in our exclusive virtual cocktails bar. Welcome to Drinks On Me."},{"property":"og:url","content":"https://drinksonme.live/"},{"property":"og:image","content":"https://drinksonme.live/og-image.jpeg"},{"property":"og:image:alt","content":"Drinks On Me"},{"property":"fb:app_id","content":"958409755525406"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/counter-page/wanna-switch-avatar.png"}],"style":[],"script":[{"src":"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6302100703852495","async":true,"crossorigin":"anonymous"}],"noscript":[],"title":"Drinks On Me - Virtual Bar","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000