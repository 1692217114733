import { createClient } from '@supabase/supabase-js'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const supabase = createClient(nuxtApp.$config.public.supabaseURL, nuxtApp.$config.public.supabaseAnonKey, {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
    },
    realtime: {
      params: {
        eventsPerSecond: 10,
      },
    },
  })
  nuxtApp.provide('supabase', supabase)
})
